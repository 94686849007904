import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import ResTable from "./restable";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import SchoolIcon from "@material-ui/icons/SchoolOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { RiHealthBookFill } from "react-icons/ri";
import { FaSyringe } from "react-icons/fa";
import PersonIcon from '@material-ui/icons/Person';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { useConfirm } from "material-ui-confirm";
import { apiurl } from '../app/prefs.js';
import CloseIcon from "@material-ui/icons/Close"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Dialog, DialogContent } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import {
  usePopupState,
  bindHover,
} from "material-ui-popup-state/hooks";
import "animate.css";
import "./style.css";
import {
  Hrres,
  Materials,
  matheaders,
  hrheaders,
  hrheadersFull,
  procedureinfo,
  ProceduresList,
} from "./data";
import { IconButton, Popover } from "@material-ui/core";
import TipsTable from "./procedureTips.js";
import LibraryIcon from '@material-ui/icons/LocalLibraryOutlined';
import { ReactComponent as PdfIcon } from '../reports/pdf17.svg';

const VideoIcon = (props) => (
  <svg
    class="svg-icon"
    width='26px'
    height='26px'
    color='#0b6471'
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M725.333333 574.506667l170.666667 113.770666V335.722667l-170.666667 113.770666v125.013334z m0-227.562667l189.653334-126.442667C943.36 201.6 981.333333 221.930667 981.333333 256v512c0 34.069333-37.973333 54.4-66.346666 35.498667L725.333333 677.056v48.106667C725.333333 784.085333 677.504 832 618.752 832H149.248A106.666667 106.666667 0 0 1 42.666667 725.162667V298.837333C42.666667 239.914667 90.496 192 149.248 192h469.504A106.666667 106.666667 0 0 1 725.333333 298.837333v48.106667zM128 298.837333v426.325334A21.333333 21.333333 0 0 0 149.248 746.666667h469.504c11.584 0 21.248-9.685333 21.248-21.504V298.837333A21.333333 21.333333 0 0 0 618.752 277.333333H149.248C137.664 277.333333 128 287.018667 128 298.837333z" fill="#0b6471" /></svg>
)

const PlayIcon = () => (
  <svg width="40px" height="40px" viewBox="-1 0 14 14" id="meteor-icon-kit__solid-play-s" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.00154V13.0015C0 13.7788 0.84797 14.259 1.5145 13.859L11.5145 7.859C12.1618 7.4706 12.1618 6.5325 11.5145 6.1441L1.5145 0.14405C0.84797 -0.25586 0 0.22425 0 1.00154z" fill="#758CA3"></path></g></svg>
)

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    //display: 'none',
    backgroundColor: "white",
    color: "black",
    maxWidth: 600,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "100",
    //border: '1px solid #FE8E3C',
    boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
  },
}))(Tooltip);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

export default function ProcedureCostsTable() {
  const mounted = React.useRef(false);
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [isSaved, setIsSaved] = React.useState(true);
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const [procedureLength, setProcedureLength] = React.useState(
    procedureinfo.length
  );
  const [hrAssignments, setHRAssignments] = React.useState([]);
  const [matAssignments, setMatAssignments] = React.useState(
    []
  );
  const [procedureCosts, setProcedureCosts] = React.useState({
    hr: 0,
    material: 0,
    total: 0,
  });
  const [notes, setNotes] = React.useState("");
  const handleNotesChange = (event) => setNotes(event.currentTarget.value);
  const [selectedYard, setSelectedYard] = React.useState("");
  const handleYardChange = (newValue) => {
    setSelectedYard(newValue);
    setSelectedProcedure(null);
    setIsSaved(true);
  };
  const [selectedProcedure, setSelectedProcedure] = React.useState("");
  const [saveInfoOpen, setSaveInfoOpen] = React.useState(false);
  const [whatsNewInfoOpen, setWhatsNewInfoOpen] = React.useState(true);
  const [userData, setUserData] = React.useState(null);
  const [procedureCopyDialogOpen, setProcedureCopyDialogOpen] = React.useState(false);
  const [procedureLibraryOpen, setProcedureLibraryOpen] = React.useState(false);

  const canExportPdf = () => {
    const role = user["https://coplus.pl/md"].permissions.role;
    let canExport = false;
    if (role !== undefined) {
      canExport = ['Admin', 'LocalAdmin', 'ProcedureAdmin'].includes(role.toString());
    }
    return canExport ? true : false
  }

  const downloadFile = (downloadURL) => {
    getAccessTokenSilently().then((token) => {
      document.body.style.cursor = 'wait';
      const apiUrl = downloadURL
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Dokumentacja procedur.pdf`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          document.body.style.cursor = 'auto';
        });
    });
  };

  const handleSaveInfoClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSaveInfoOpen(false);
  };

  const handleWhatsNewInfoClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWhatsNewInfoOpen(false);
  };

  const [procdata, setProcedures] = React.useState({ procedures: undefined, wardsList: undefined });
  const [hrList, setHRList] = React.useState(undefined);
  const [materialsList, setMaterialsList] = React.useState(undefined);
  const [procedureListBoxOpen, setProcedureListBoxOpen] = React.useState(false);
  const [wardListBoxOpen, setWardListBoxOpen] = React.useState(false);
  const confirm = useConfirm();

  const [size, setSize] = React.useState([70, 30]);
  const handleResize = (gutterIdx, newSizes) => {
    setSize(newSizes);
  };

  //procedures library variables
  const [libraryList, setLibraryList] = React.useState([]);
  const [libraryProcedure, setLibraryProcedure] = React.useState(undefined);
  const [libraryData, setLibraryData] = React.useState(undefined);

  const handleProcedureChange = (newValue) => {
    setSelectedProcedure(null);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      let procedureCode = newValue['Nr procedury (nr ICD-9)'];
      if (typeof procedureCode === 'string' || procedureCode instanceof String) {
        procedureCode = procedureCode.replace(/\//g, '%2F')
      };

      fetch(
        `${apiurl}/hashproceduredata/${selectedYard.wardID}/${procedureCode}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setHRAssignments(data.response.hr);
          setMatAssignments(data.response.materials);
          setNotes(data.response.notes);
          setProcedureLength(data.response.info.length);
          procedureinfo.length = data.response.info.length;
          procedureinfo.date = data.response.info.date
            ? data.response.info.date
            : new Date().toJSON().slice(0, 10);
          procedureinfo.described_by = data.response.info.described_by
            ? data.response.info.described_by
            : user && user.name;
          procedureinfo.consulted_with = data.response.info.consulted_with
            ? data.response.info.consulted_with
            : "-";
          setProcedureCosts(
            data.response.info.costs
              ? data.response.info.costs && {
                hr: (userData.app && userData.app.showHRCosts === 'true') ? data.response.info.costs.hr : 0,
                material: data.response.info.costs.material,
                total: (((userData.app && userData.app.showHRCosts === 'true') ? data.response.info.costs.hr : 0) + data.response.info.costs.material)
              }
              : { hr: 0, material: 0, total: 0 }
          );
          setSelectedProcedure(newValue);
          setIsSaved(true);
        });
    });
  };

  const setHRCosts = (cost) => {
    setProcedureCosts((prevState) => ({
      ...prevState,
      hr: (userData.app && userData.app.showHRCosts === 'true') ? cost : 0,
      total: prevState.material + cost,
    }));
  };

  const setMaterialCosts = (cost) => {
    setProcedureCosts((prevState) => ({
      ...prevState,
      material: cost,
      total: prevState.hr + cost,
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleLengthChange = (event) => {
    let newVal = event.currentTarget.value.replace(":", "");
    newVal = newVal.replace("_", "");
    procedureinfo.length = newVal;
    setProcedureLength(newVal);
  };

  const fetch_retry = (url, options, n) => fetch(url, options).catch(function (error) {
    if (n === 1) throw error;
    return fetch_retry(url, options, n - 1);
  });

  const getData = () => {
    getAccessTokenSilently().then((token) => {
      getUserData(token);
      getLibraryList(token);
      ProceduresList(setProcedures, token);
      Hrres(setHRList, token);
      Materials(setMaterialsList, token);
    });
  }

  const canSeeSalaries = (requiredRights) => {
    let checkResult = false;

    checkResult =
      isAuthenticated
      && user["https://coplus.pl/md"]
      && user["https://coplus.pl/md"].permissions
      && user["https://coplus.pl/md"].permissions.role
      && requiredRights.some(
        role => user["https://coplus.pl/md"].permissions.role.includes(role))

    return checkResult;
  }

  const getUserData = (token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch_retry(`${apiurl}/getuserdata`, requestOptions, 10)
        .then((response) => response.json(), reason => console.log(reason))
        .then((data) => {
          setUserData(data);
        }, reason => console.log(reason))
        .catch(error => { });
    }
  };

  const getLibraryList = (token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch_retry(`${apiurl}/procedureslibrary`, requestOptions, 10)
        .then((response) => response.json(), reason => console.log(reason))
        .then((data) => {
          setLibraryList(data);
        }, reason => console.log(reason))
        .catch(error => { });
    }
  };

  const ProcedureCopySelection = () => {
    const [copySelectedWard, setCopySelectedWard] = React.useState(null);
    const [copySelectedProcedure, setCopySelectedProcedure] = React.useState(null);
    const [dataToCopy, setDataToCopy] = React.useState(null);

    const handleCopyProcedureChange = (newValue) => {
      setCopySelectedProcedure(null);

      getAccessTokenSilently().then((token) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };

        let procedureCode = newValue['Nr procedury (nr ICD-9)'];
        if (typeof procedureCode === 'string' || procedureCode instanceof String) {
          procedureCode = procedureCode.replace(/\//g, '%2F')
        };

        fetch(
          `${apiurl}/hashproceduredata/${copySelectedWard.wardID}/${procedureCode}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            setDataToCopy(data);
            setCopySelectedProcedure(newValue);
          });
      });
    };

    const copyProcedureData = (data) => {
      setHRAssignments(data.response.hr);
      setMatAssignments(data.response.materials);
      setNotes(data.response.notes);
      setProcedureLength(data.response.info.length);
      procedureinfo.length = data.response.info.length;
      setProcedureCosts(
        data.response.info.costs
          ? data.response.info.costs && { hr: data.response.info.costs.hr, material: data.response.info.costs.material, total: (data.response.info.costs.hr + data.response.info.costs.material) }
          : { hr: 0, material: 0, total: 0 }
      );
    }

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
          <Autocomplete
            style={{
              minWidth: "300px",
              marginBottom: "10px",
            }}
            size="small"
            value={copySelectedWard}
            getOptionLabel={(option) => option.wardName}
            onChange={(event, newValue) => {
              setCopySelectedWard(newValue);
              setCopySelectedProcedure(null);
            }}
            id='copy_1'
            options={procdata.wardsList}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="Komórka realizująca"
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  style: {
                    backgroundColor: "#e7f0f1",
                    color: "#0B6471",
                    fontSize: "14px",
                  },
                }}
              />
            )}
          />

          <Autocomplete
            disabled={copySelectedWard ? false : true}
            style={{
              minWidth: "400px",
              marginBottom: "10px",
            }}
            size="small"
            options={
              copySelectedWard &&
              procdata.procedures.filter(
                (proc) => proc['Nazwa konta OPK Proceduralny'] === copySelectedWard.wardName
              )
            }
            getOptionLabel={(option) => option && (option['Nr procedury (nr ICD-9)'] + ': ' + option['Nazwa procedury'])}
            renderOption={
              (option, state) =>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaSyringe style={{ color: option.materials === 1 ? '#0B6471' : 'lightgray', fontSize: '14px' }} />
                  <PersonIcon style={{ color: option.hr === 1 ? '#0B6471' : 'lightgray', fontSize: '18px', marginRight: '5px' }} />
                  {option['Nr procedury (nr ICD-9)'] + ': ' + option['Nazwa procedury']}
                </div>
            }
            value={copySelectedProcedure}
            onChange={(event, newValue) => handleCopyProcedureChange(newValue)}
            id="copy_2"
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Procedura"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,

                  style: {
                    backgroundColor: "#e7f0f1",
                    color: "#0B6471",
                    fontSize: "14px",
                  },
                }}
              />
            )}
          />
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            style={{ width: '140px', marginRight: '10px', backgroundColor: '#4E97A5', color: 'white' }}
            onClick={() => setProcedureCopyDialogOpen(false)}>
            Anuluj
          </Button>
          <Button
            disabled={!copySelectedProcedure}
            variant="contained"
            style={{ width: '140px', backgroundColor: copySelectedProcedure ? '#4E97A5' : 'lightgray', color: 'white', fontWeight: '800' }}
            onClick={() => {
              copyProcedureData(dataToCopy);
              setProcedureCopyDialogOpen(false)
            }}>
            Kopiuj
          </Button>
        </div>
      </div>
    )
  }

  const TableFromData = (props) => {
    return (
      <TableContainer>
        <Table size="small">
          <TableBody>
            {props.data.map(
              (row) => {
                return <TableRow>
                  {row.map(column =>
                    <TableCell style={{ fontSize: '12px', fontWeight: column.fontWeight, paddingBottom: '0px', borderBottom: '1px solid lightgray' }} align={column.justify} >{column.text}</TableCell>
                  )}
                </TableRow>
              })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const tips = [
    { objectId: "wardSelectComboBox", text: "Tu wybierz komórkę wykonującą procedury" },
    { objectId: "procedureSelectComboBox", text: "Tu wybierz procedurę, która zostanie opisana" },
    {
      objectId: "editProcedureLength",
      text:
        "Wpisz czas realizacji procedury (w minutach). Pamiętaj, że należy uwzględnić czas przygotowania, wykonania i odnotowania (opisania) wyników"
    },
    {
      objectId: "editMaterials",
      text:
        'Mając aktywne „Zasoby materiałowe” wybierz „Dodaj”. Wyszukaj i zaznacz materiał wykorzystywany w trakcie wykonywania procedury. Sprawdź jaka jest wielkość opakowania i uzupełnij „Ilość zużyta na 1 procedurę”. Możesz wpisać liczbę całkowitą, ułamek dziesiętny lub zwykły. Na zakończenie wybierz „Zamknij”.'
    },
    {
      objectId: "editHR",
      text:
        'Mając aktywne „Zasoby osobowe” wybierz „Dodaj”. Wyszukaj i zaznacz grupę pracowniczą realizującą procedurę. Uzupełnij kolumny „Czas wykonana procedury (minuty)” oraz „Liczba pracowników” (tzn. ilu pracowników, z danej grupy, bierze udział w realizacji opisywanej procedury). W przypadku, gdy dana grupa wynagradzana jest za procedurę uzupełnić należy tylko „Liczba pracowników”. Na zakończenie wybierz „Zamknij”.'
    },
    {
      objectId: "saveProcedureButton",
      text:
        'W każdym momencie edycji możesz dokonać zapisu. Program dokonuje automatycznego zapisu co 1 minutę.'
    },
    {
      objectId: "copyProcedureButton",
      text:
        'Wykorzystaj tę funkcję i skopiuj opis z innej procedury.'
    },
    {
      objectId: "openLibraryButton",
      text:
        'Wykorzystaj tę funkcję i otwórz Bibliotekę Przykładowych Opisów Procedur, a następnie zapoznaj się z wzorcowymi opisami oraz wskazówkami pomocnymi przy opisie procedur.'
    },
    {
      objectId: "saveSinglePdf",
      text:
        'Wykorzystaj tę funkcję i wyeksportuj opisaną procedurę do pliku PDF z miejscem na podpis osoby odpowiedzialnej.'
    },
    {
      objectId: "multi",
      text:
        'Wykorzystaj tę funkcję i wyeksportuj wszystkie procedury z wybranej komórki do pliku PDF z miejscem na podpis osoby odpowiedzialnej'
    }

  ];

  const [activeHint, setActiveHint] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = async (event) => {
    setActiveHint(0);
    setAnchorEl(document.getElementById(tips[0].objectId));
  };

  const handleNext = () => {
    setActiveHint(activeHint + 1);
    setAnchorEl(document.getElementById(tips[activeHint + 1].objectId));
  };

  const handlePrev = () => {
    setActiveHint(activeHint - 1);
    setAnchorEl(document.getElementById(tips[activeHint - 1].objectId));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [videoListOpen, setVideoListOpen] = React.useState(false);
  const [videoPlayDialogOpen, setVideoPlayDialogOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(undefined);

  const handleVideoListClose = () => {
    setVideoListOpen(false)
  };

  const hintPopOverOpen = Boolean(anchorEl);

  React.useEffect(() => {
    getData();
    return () => {
    };
  }, []);


  React.useEffect(() => {
    let interval = null;
    if (!isSaved) {
      window.interval = setTimeout(() => { setSaveInfoOpen(true); handleSave(); }, 60000);
    } else {
      clearTimeout(window.interval)
    }
  }, [isSaved]);


  React.useEffect(() => {
    if (mounted.current) {
      setIsSaved(false);
    } else {
      mounted.current = true;
    }
  }, [hrAssignments, matAssignments, procedureLength, notes]);

  const handleSave = () => {
    procedureinfo.date = new Date().toJSON().slice(0, 10);
    let dataToSave = {};
    Object.assign(dataToSave, { procedureinfo: procedureinfo });
    Object.assign(dataToSave, { matAssignments: matAssignments });
    Object.assign(dataToSave, { hrAssignments: hrAssignments });
    Object.assign(dataToSave, { notes: notes });
    Object.assign(dataToSave, { ward: selectedYard });
    Object.assign(dataToSave, { procedure: selectedProcedure });
    Object.assign(dataToSave.procedureinfo, { costs: procedureCosts });

    //console.log(dataToSave);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, },
        body: JSON.stringify(dataToSave),
      };
      fetch(`${apiurl}/proceduredata`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });

      setIsSaved(true);
      popupState.close();
    });

  };

  const videoData = [
    {
      image: '/manual/0_Procedury_wstep.jpg',
      video: '/manual/0_Procedury_wstep.mp4'
    },
    {
      image: '/manual/1_Jak_wyglada_okno.jpg',
      video: '/manual/1_Jak_wyglada_okno.mp4'
    },
    {
      image: '/manual/2_Jak_przypisac_materialy.jpg',
      video: '/manual/2_Jak_przypisac_materialy.mp4'
    },
    {
      image: '/manual/3_Jak_przypisac_osoby.jpg',
      video: '/manual/3_Jak_przypisac_osoby.mp4'
    },
    {
      image: '/manual/4_Jak_wpisac_ilosc.jpg',
      video: '/manual/4_Jak_wpisac_ilosc.mp4'
    },
    {
      image: '/manual/5_Jak_kopiowac_opisy.jpg',
      video: '/manual/5_Jak_kopiowac_opisy.mp4'
    },
    {
      image: '/manual/6_Jaki_jest_czas.jpg',
      video: '/manual/6_Jaki_jest_czas.mp4'
    },
    {
      image: '/manual/7_Biblioteka.jpg',
      video: '/manual/7_Biblioteka.mp4'
    },
  ];

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setVideoListOpen(false);
    setVideoPlayDialogOpen(true);
  };

  const VideoList = () => {
    return <div>
      {
        videoData.map(
          (v, i) => {
            return <div className="videoListItem" onClick={() => handleVideoSelect(v)}>
              <div style={{ position: 'relative' }}>
                <img src={v.image} alt={'Video nr ' + i} />
                <div style={{
                  position: 'absolute',
                  top: '90px',
                  left: '190px',
                  opacity: '.5',
                  color: 'gray'
                }}>
                  <PlayIcon />
                </div>
              </div>
            </div>
          }
        )
      }
    </div>
  }

  return (
    (userData && procdata.procedures && procdata.wardsList && materialsList && hrList)
      ? <div>
        <div
          style={{
            background: "linear-gradient(to right, #0b6471, #9BC0C5)",
            display: "flex",
            height: "55px",
            width: "100%",
            backgroundColor: "#0b6471",
            color: "white",
            borderRadius: "5px",
            paddingTop: "0px",
            paddingLeft: "0px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "80px", marginTop: "5px" }}>
            <RiHealthBookFill
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "42px",
              }}
            />
          </div>
          <div>
            <Typography variant="h5" style={{ color: "white" }}>
              OPIS TECHNOLOGICZNY PROCEDURY
            </Typography>
          </div>
        </div>
        <p style={{ height: "5px" }} />

        <Splitter
          direction={SplitDirection.Horizontal}
          initialSizes={procedureLibraryOpen ? size : [100, 0]}
          onResizeFinished={handleResize}
          minWidths={[800, 200]}
          gutterClassName='gutter'>

          <Paper
            className={classes.root}
            style={{ display: "flex", flexDirection: "column", width: "100%", minHeight: '800px' }}
          >
            <div
              id="topInfo"
              style={{
                display: "flex",
                flexDirection: "row",
                flexShrink: "s",
                padding: "5px",
                paddingTop: "0px",
                marginBottom: "10px",
                marginRight: "0px",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={saveInfoOpen}
                autoHideDuration={3000}
                onClose={handleSaveInfoClose}
              >
                <SnackbarContent style={{
                  backgroundColor: '#4E97A5',
                }}
                  message={<span id="client-snackbar">Zapisano</span>}
                />
              </Snackbar>
              <Snackbar

                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={whatsNewInfoOpen}

                onClose={handleWhatsNewInfoClose}
              >
                <SnackbarContent style={{
                  backgroundColor: '#0b6471',
                  fontSize: '16px'
                }}
                  message={
                    <div id="client-snackbar">
                      <div>
                        Podczas opisywania procedur można teraz wpisywać ułamki oraz formuły:
                        <br />
                        <strong>PRZYKŁAD 1:</strong> Rękawiczki opakowanie <strong>50 szt</strong>. W trakcie procedury zużywane są <strong>3 pary (czyli 6 sztuk)</strong>. W „Ilość zużyta na 1 procedurę” wpisz: <strong>6/50</strong><br />
                        <strong>PRZYKŁAD 2:</strong> Kompres jałowy opakowanie zawiera <strong>3 sztuki</strong>. W trakcie procedury zużywane są <strong>4 sztuki</strong>. W „Ilość zużyta na 1 procedurę” wpisz: <strong>1+ 1/3</strong><br />
                        <strong>PRZYKŁAD 3:</strong> Płyn butelka <strong>200 ml</strong>. Opakowanie wystarcza na <strong>6 procedur</strong>. W „Ilość zużyta na 1 procedurę” wpisz: <strong>1/6</strong><br />
                        <strong>PRZYKŁAD 4:</strong> Pracownik <strong>jedną</strong> czynność wykonuje <strong>10 minut</strong> na rzecz <strong>4 procedur</strong>. W „Czas wykonania procedury (minuty)” wpisz: <strong>10/4</strong>
                      </div>
                    </div>
                  }
                  action={
                    <React.Fragment>
                      <IconButton color="inherit" onClick={handleWhatsNewInfoClose}>
                        <CloseIcon />
                      </IconButton>
                    </React.Fragment>
                  }
                />
              </Snackbar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignContent: "space-between",
                  alignItems: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "5px",
                    boxShadow: "none",
                    justifyContent: "flex-start",
                    alignContent: "",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    id='wardSelectComboBox'
                    className={hintPopOverOpen && tips[activeHint].objectId === 'wardSelectComboBox' && 'jumpingHint'}
                    style={{
                      minWidth: "300px",
                      marginRight: "5px",
                      marginTop: "0px",
                      marginBottom: '5px',

                    }}
                    size="small"
                    value={selectedYard}
                    getOptionLabel={(option) => option.wardName}
                    onChange={(event, newValue) => handleYardChange(newValue)}
                    onOpen={(event) => {
                      if (!isSaved) {
                        confirm({
                          title: "Zapisać zmiany w edytowanej procedurze?",
                          cancellationText: "Nie zapisuj",
                          confirmationText: "Zapisz",
                          dialogProps: { style: { zIndex: 10000 } },
                        })
                          .then(() => {
                            handleSave();
                            setWardListBoxOpen(true);
                          }
                          )
                          .catch(() => setWardListBoxOpen(true));
                      }
                      else setWardListBoxOpen(true);
                    }}
                    open={wardListBoxOpen}
                    onClose={() => setWardListBoxOpen(false)}
                    options={procdata.wardsList}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Komórka realizująca"
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            backgroundColor: "#e7f0f1",
                            color: "#0B6471",
                            fontSize: "14px",
                          },
                        }}
                      />
                    )}
                  />

                  <Autocomplete
                    id='procedureSelectComboBox'
                    disabled={selectedYard ? false : true}
                    className={hintPopOverOpen && tips[activeHint].objectId === 'procedureSelectComboBox' && 'jumpingHint'}
                    style={{
                      minWidth: "400px",
                      marginRight: "10px",
                      width: "800px",
                      marginBottom: '5px',
                    }}
                    size="small"
                    options={
                      selectedYard &&
                      procdata.procedures.filter(
                        (proc) => proc['Numer konta OPK Proceduralny'] === selectedYard.wardID
                      )
                    }
                    getOptionLabel={(option) => option && (option['Nr procedury (nr ICD-9)'] + ': ' + option['Nazwa procedury'])}
                    renderOption={
                      (option, state) =>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FaSyringe style={{ color: option.materials === 1 ? '#0B6471' : 'lightgray', fontSize: '14px' }} />
                          <PersonIcon style={{ color: option.hr === 1 ? '#0B6471' : 'lightgray', fontSize: '18px', marginRight: '5px' }} />
                          {option['Nr procedury (nr ICD-9)'] + ': ' + option['Nazwa procedury']}
                        </div>
                    }
                    value={selectedProcedure}
                    onChange={(event, newValue) => handleProcedureChange(newValue)}
                    onOpen={(event) => {
                      if (!isSaved) {
                        confirm({
                          title: "Zapisać zmiany w edytowanej procedurze?",
                          cancellationText: "Nie zapisuj",
                          confirmationText: "Zapisz",
                          dialogProps: { style: { zIndex: 10000 } },
                        })
                          .then(() => {
                            handleSave();
                            setProcedureListBoxOpen(true);
                          }
                          )
                          .catch(() => setProcedureListBoxOpen(true));
                      }
                      else setProcedureListBoxOpen(true);
                    }}
                    open={procedureListBoxOpen}
                    onClose={() => setProcedureListBoxOpen(false)}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Procedura"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,

                          style: {
                            backgroundColor: "#e7f0f1",
                            color: "#0B6471",
                            fontSize: "14px",
                          },
                        }}
                      />
                    )}
                  />
                  <Tooltip title='Zapis'>
                    <Button
                      id='saveProcedureButton'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'saveProcedureButton' && 'jumpingHint'}
                      disabled={isSaved}
                      onClick={() => handleSave()}
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: selectedProcedure && "#0B6471",
                        borderRadius: "5px",
                        marginRight: "10px",
                        marginLeft: '0px',
                      }}
                    >
                      <SaveIcon
                        {...(!(
                          user &&
                          user["https://coplus.pl/md"].permissions.preview === "yes"
                        ) &&
                          selectedProcedure &&
                          !isSaved &&
                          bindHover(popupState))}
                        style={{
                          marginTop: "0px",
                          width: "48px",
                          height: "46px",
                          backgroundColor: "#e7f0f1",
                          //color: "#0B6471",
                          color:
                            isSaved ||
                              (user &&
                                user["https://coplus.pl/md"].permissions.preview ===
                                "yes")
                              ? "lightgray"
                              : "#fe8e3c",
                          borderRadius: "5px",
                        }}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip title='Kopiowanie opisu procedury'>
                    <Button
                      id='copyProcedureButton'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'copyProcedureButton' && 'jumpingHint'}
                      disabled={!selectedProcedure}
                      onClick={() => {
                        setProcedureCopyDialogOpen(true)
                      }
                      }
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: selectedProcedure && "#0B6471",
                        borderRadius: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <FileCopyOutlinedIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip title='Eksport wybranej procedury do PDF'>
                    <Button
                      id='saveSinglePdf'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'saveSinglePdf' && 'jumpingHint'}
                      disabled={!canExportPdf() || !selectedProcedure}
                      onClick={
                        () => {
                          let procedureCode = selectedProcedure['Nr procedury (nr ICD-9)'];
                          procedureCode = procedureCode.replace(/\//g, '%2F');
                          downloadFile(`${apiurl}/procedurePdf/procedure/${selectedYard.wardID}/${procedureCode}`)
                        }
                      }
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: hintPopOverOpen ? '#FE8E3C' : "#0B6471",
                        borderRadius: "5px",
                        marginRight: '10px',
                      }}
                    >
                      <PdfIcon
                        style={{ marginTop: '2px', cursor: 'pointer', opacity: (!canExportPdf() || !selectedProcedure) && '0.3' }}
                      />
                    </Button>
                  </Tooltip>


                  <Tooltip title='Eksport wszystkich procedur z wybranego OPK do PDF'>
                    <Button
                      id='multi'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'multi' && 'jumpingHint'}
                      disabled={!canExportPdf() || !selectedYard}
                      onClick={
                        () => downloadFile(`${apiurl}/procedurePdf/opk/${selectedYard.wardID}`)
                      }
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: hintPopOverOpen ? '#FE8E3C' : "#0B6471",
                        borderRadius: "5px",
                        marginRight: '10px',
                      }}
                    >
                      <PdfIcon
                        style={{ marginTop: '2px', cursor: 'pointer', opacity: (!canExportPdf() || !selectedYard) && '0.3' }}
                      />
                    </Button>
                  </Tooltip>

                  <HtmlTooltip
                    placement="bottom-start"
                    title={
                      <React.Fragment>
                        Data aktualizacji
                        <Typography>
                          {selectedProcedure
                            ? procedureinfo && procedureinfo.date : "-"}
                        </Typography>
                        Wykonawca opisu
                        <Typography>
                          {selectedProcedure
                            ? procedureinfo && procedureinfo.described_by : "-"}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      disabled={!selectedProcedure}
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: selectedProcedure && "#0B6471",
                        borderRadius: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <InfoIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </Button>
                  </HtmlTooltip>

                  <Tooltip title='Biblioteka przykładowych opisów procedur'>
                    <Button
                      id='openLibraryButton'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'openLibraryButton' && 'jumpingHint'}
                      onClick={() => {
                        setProcedureLibraryOpen(!procedureLibraryOpen)
                      }
                      }
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: procedureLibraryOpen ? '#FE8E3C' : "#0B6471",
                        borderRadius: "5px",
                        marginRight: '10px',
                      }}
                    >
                      <SchoolIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip title='Instrukcje video'>
                    <Button
                      id='video'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'video' && 'jumpingHint'}
                      onClick={
                        () => setVideoListOpen(true)
                      }
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: hintPopOverOpen ? '#FE8E3C' : "#0B6471",
                        borderRadius: "5px",
                        marginRight: '10px',
                      }}
                    > <sup style={{ position: 'absolute', color: '#FE8E3C', fontWeight: 'bold', top: '0px', left: '4px' }}>N</sup>
                      <VideoIcon
                        style={{ color: '#0B6471', marginTop: '2px', cursor: 'pointer' }}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip title='Samouczek'>
                    <Button

                      onClick={() => {
                        handleOpen()
                      }
                      }
                      style={{
                        marginTop: "0px",
                        width: "48px",
                        minWidth: "48px",
                        height: "46px",
                        backgroundColor: "#e7f0f1",
                        color: hintPopOverOpen ? '#FE8E3C' : "#0B6471",
                        borderRadius: "5px",
                        marginRight: '10px',
                      }}
                    >
                      <LibraryIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </Button>
                  </Tooltip>

                  {/*
              <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem style={{ fontSize: "14px" }} onClick={handleSave}>
                  Zapisz
                </MenuItem>
                
                <MenuItem
                  style={{ fontSize: "14px" }}
                  onClick={popupState.close}
                >
                  Zapisz i zakończ edycję
                </MenuItem>
              </Menu>
               */}
                </div>
                <div style={{ padding: "5px", display: "flex", flexWrap: "wrap" }}>

                  <TableContainer >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width='70px' style={{ fontSize: '12px', paddingBottom: '0px', borderBottom: '0px' }} align="right">Koszt bezp. procedury</TableCell>
                          <TableCell width='70px' style={{ fontSize: '12px', paddingBottom: '0px', borderBottom: '0px' }} align="right">Zasoby materiałowe</TableCell>
                          <TableCell width='70px' style={{ fontSize: '12px', paddingBottom: '0px', borderBottom: '0px' }} align="right">Zasoby osobowe</TableCell>
                          <TableCell width='250px' style={{ fontSize: '12px', paddingBottom: '0px', borderBottom: '0px' }} align="right">Czas realizacji procedury w minutach (czas przygotowania, wykonania i odnotowania)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="right" style={{ borderBottom: '0px', paddingTop: '0px' }}>
                            <TextField
                              inputProps={{ min: 0, style: { textAlign: 'right' } }}
                              InputProps={{
                                disableUnderline: true,
                                textAlign: 'right',
                                readOnly: true,
                                value: selectedProcedure && procedureCosts.total
                                  ? `${procedureCosts.total ? procedureCosts.total.toFixed(2) : '0.00'} zł`
                                  : "-",
                                style: {
                                  color: "#0B6471",
                                  fontSize: "22px",
                                  fontWeight: "500",
                                  marginTop: '0px',
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" style={{ borderBottom: '0px', paddingTop: '0px' }}>
                            <TextField
                              inputProps={{ min: 0, style: { textAlign: 'right' } }}
                              InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                value: selectedProcedure && procedureCosts.material
                                  ? `${procedureCosts.material.toFixed(2)} zł`
                                  : "-",
                                style: {
                                  color: "#0B6471",
                                  fontSize: "22px",
                                  fontWeight: "500",
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" style={{ borderBottom: '0px', paddingTop: '0px' }}>
                            <TextField
                              inputProps={{ min: 0, style: { textAlign: 'right' } }}
                              InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                value: (selectedProcedure && userData.app && userData.app.showHRCosts === 'true' && procedureCosts.hr && procedureCosts.hr !== null
                                  && canSeeSalaries(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling',]))
                                  ? `${procedureCosts.hr.toFixed(2)} zł`
                                  : "-",
                                style: {
                                  color: "#0B6471",
                                  fontSize: "22px",
                                  fontWeight: "500",
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" style={{ borderBottom: '0px', paddingTop: '0px' }}>
                            <TextField
                              id='editProcedureLength'
                              style={{ width: "185px", textJustify: 'right' }}
                              className={hintPopOverOpen && tips[activeHint].objectId === 'editProcedureLength' && 'jumpingHint'}
                              inputProps={{ style: { textAlign: 'right', paddingRight: '10px' } }}
                              InputProps={{
                                disableUnderline: true,
                                readOnly: false,

                                onChange: event => handleLengthChange(event),
                                value: selectedProcedure
                                  ? `${procedureLength}`
                                  : "-",
                                style: {
                                  color: selectedProcedure ? procedureLength !== 0 ? "#0B6471" : "#FE8E3C" : "#0B6471",
                                  backgroundColor: selectedProcedure && procedureLength === '' ? "#FE8E3C" : '#e7f0f1',
                                  borderRadius: "5px",
                                  fontSize: "22px",
                                  fontWeight: "500",

                                },
                              }}

                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >

              </div>
            </div>

            <div>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={{ width: '500px' }}
                aria-label="tabs"
              >
                <Tab
                  key="2"
                  label={
                    <div
                      //className={"MuiTabItem-labelGroup"} 
                      id='editMaterials'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'editMaterials' && 'jumpingHint'}
                      style={{ backgroundColor: value === 0 ? '#E2EDEE' : '#F2F2F2', width: '200px', borderRadius: '7px', height: '40px' }}>
                      <div
                        style={{
                          fontSize: "10px",
                          textAlign: "center",
                          color: "#4E97A5",
                        }}
                      >
                        Zasoby Materiałowe
                      </div>
                      <FaSyringe style={{ fontSize: '12px' }} />
                      {false && <div
                        style={{
                          fontSize: "18px",
                          textAlign: "center",
                          color: "#0B6471",
                          textTransform: "none",
                        }}
                      >
                        {selectedProcedure && procedureCosts.material
                          ? procedureCosts.material.toFixed(2)
                          : "-"}{" "}
                        zł
                      </div>}
                    </div>
                  }
                />
                <Tab
                  key="1"
                  label={
                    <div
                      //className={"MuiTabItem-labelGroup"} 
                      id='editHR'
                      className={hintPopOverOpen && tips[activeHint].objectId === 'editHR' && 'jumpingHint'}
                      style={{ backgroundColor: value === 1 ? '#E2EDEE' : '#F2F2F2', width: '200px', borderRadius: '7px', height: '40px' }}>
                      <div
                        style={{
                          fontSize: "10px",
                          textAlign: "center",
                          color: "#4E97A5",
                        }}
                      >
                        Zasoby Osobowe
                      </div>
                      <PersonIcon style={{ fontSize: '16px' }} />
                      {false && <div
                        style={{
                          fontSize: "18px",
                          textAlign: "center",
                          color: "#0B6471",
                          textTransform: "none",
                        }}
                      >
                        {(selectedProcedure && userData.app && userData.app.showHRCosts === 'true' && procedureCosts.hr && procedureCosts.hr !== null
                          && canSeeSalaries(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling',])) ? (procedureCosts.hr.toFixed(2)) : "-"} zł
                      </div>}
                    </div>
                  }
                />
              </Tabs>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel key="2" value={value} index={0} dir={theme.direction}>
                  {selectedProcedure && (
                    <ResTable
                      procedureName={selectedProcedure}
                      filterColumn={["Nazwa", "Kod"]}
                      resources={materialsList}
                      assignments={matAssignments}
                      headers={matheaders}
                      datakey="Indeks materiału"
                      dictkey="Kod"

                      formula={`calc(res['Ilość zużyta na 1 procedurę']) *
                         relatedData(res[props.datakey])['Koszt jednostkowy']`}
                      formula2={`calc(relatedData(res[props.dictkey])['Ilość zużyta na 1 procedurę']) *
                   res['Koszt jednostkowy']`}
                      setProcedureCosts={setMaterialCosts}
                      setAssignments={setMatAssignments}
                      multiRowEdit={false}
                    />
                  )}
                </TabPanel>
                <TabPanel key="1" value={value} index={1} dir={theme.direction}>
                  {selectedProcedure && (
                    <ResTable
                      procedureName={selectedProcedure}
                      resources={hrList}
                      filterColumn={["Grupa zaszeregowania", "Nazwa OPK", "Nazwa procedury"]}
                      assignments={hrAssignments}
                      headers={(userData && userData.app.showHRCosts === 'true' && canSeeSalaries(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling',])) ? hrheadersFull : hrheaders}
                      datakey="Indeks pracownika"
                      dictkey="Id grupy"
                      formula={`(calc(res['Liczba pracowników']) *
                            (relatedData(res[props.datakey])['Jednostka miary'] === 'procedura' ? 60 : calc(res['Czas wykonania procedury (minuty)'])) ) * 
                            relatedData(res[props.datakey])['Koszt jednostkowy']/60`}
                      formula2={`calc(relatedData(res[props.dictkey])['Liczba pracowników']) * (res['Jednostka miary'] === 'procedura' ? 60 : calc(relatedData(res[props.dictkey])['Czas wykonania procedury (minuty)'])) * res['Koszt jednostkowy']/60`}
                      setProcedureCosts={setHRCosts}
                      setAssignments={setHRAssignments}
                      multiRowEdit={true}
                    />
                  )}
                </TabPanel>
              </SwipeableViews>
              <TextField
                disabled={selectedProcedure ? false : true}
                style={{ width: "100%", marginTop: "10px" }}
                id="filled-multiline-static"
                label="Notatki"
                value={notes}
                onChange={handleNotesChange}
                multiline
                minRows={3}
                variant="filled"
                InputProps={{
                  disableUnderline: true,

                  style: {
                    backgroundColor: "#e7f0f1",
                    color: "#0B6471",
                    fontSize: "14px",
                  },
                }}
              />
            </div>
          </Paper>

          {procedureLibraryOpen &&
            <Paper
              className={classes.root}
              style={{ display: "flex", flexDirection: "column", width: "100%", minHeight: '800px', padding: '5px' }}
            >
              <Autocomplete
                disabled={libraryList.length !== 0 ? false : true}
                style={{
                  minWidth: "200px",
                  marginRight: "10px",

                  marginBottom: '5px',
                }}
                size="small"
                options={libraryList}
                getOptionLabel={(option) => option && (option.procedureId + ': ' + option.procedureName)}
                renderOption={
                  (option, state) =>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {option.procedureId + ': ' + option.procedureName}
                    </div>
                }
                value={libraryProcedure}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setLibraryProcedure(newValue);
                    getAccessTokenSilently().then((token) => {
                      const requestOptions = {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + token,
                        },
                      };

                      let procedureCode = newValue.procedureId;
                      if (typeof procedureCode === 'string' || procedureCode instanceof String) {
                        procedureCode = procedureCode.replace(/\//g, '%2F')
                      };

                      fetch(
                        `${apiurl}/procedureslibrary/${procedureCode}`,
                        requestOptions
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          setLibraryData(data);

                        })
                    })
                  } else {
                    setLibraryProcedure(undefined);
                    setLibraryData(undefined)
                  }
                }}
                id="procedureLibraryList"
                //disableClearable
                clearText="Wyczyść wybór"

                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    //label="Procedura wzorcowa"
                    placeholder="Wpisz fragment nazwy lub kodu szukanej procedury"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,

                      style: {
                        backgroundColor: "#e7f0f1",
                        color: "#0B6471",
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              />
              {(libraryData && libraryData.materials && libraryData.personel) ?
                <div>
                  <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#0B6471', marginTop: '10px', marginLeft: '15px' }}>
                    <table>
                      <tr>
                        <td>
                          Koszt bezp. procedury:
                        </td>
                        <td align="right" style={{ color: 'black' }}>
                          {Number(Number(libraryData.materials.totalCost) + Number(libraryData.personel.totalCost)).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Zasoby materiałowe:
                        </td>
                        <td align="right" style={{ color: 'black' }}>
                          {Number(Number(libraryData.materials.totalCost)).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Zasoby osobowe:
                        </td>
                        <td align="right" style={{ color: 'black' }}>
                          {Number(Number(libraryData.personel.totalCost)).toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#0B6471', marginTop: '10px', marginLeft: '15px' }}>
                    Zasoby materiałowe
                  </div>
                  <TableFromData data={libraryData.materials.table} />
                  <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#0B6471', marginTop: '30px', marginLeft: '15px' }}>
                    Zasoby osobowe
                  </div>
                  <TableFromData data={libraryData.personel.table} />
                  <div style={{ marginTop: '10px' }}>
                    Opis na podstawie materiałów dostępnych na stronie AOTMiT
                  </div>
                </div>
                : <div><TipsTable /></div>
              }
            </Paper>
          }

        </Splitter>

        <Dialog
          fullWidth
          maxWidth="lg"
          open={procedureCopyDialogOpen}
          onClose={() => setProcedureCopyDialogOpen(false)}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          //style={{ zIndex: 10000, height: "100%" }}
          style={{ height: "70%" }}
        >
          <Typography
            variant="h6"
            style={{ color: "#0b6471", marginLeft: "30px", marginTop: "10px" }}
          >
            {`Wybierz procedurę do skopiowania`}
          </Typography>
          <DialogContent>
            <ProcedureCopySelection />

          </DialogContent>
        </Dialog>

        <Popover
          id='hintPopOver'
          open={hintPopOverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
        >
          <Paper
            style={{
              padding: "10px",
              width: "400px",
              fontFamily: "roboto",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div
              style={{
                top: "0px",
                display: "flex",
                width: "100%",
                justifyContent: "right"
              }}
            >

              <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleClose()} />

            </div>
            <div style={{ fontSize: '16px', padding: '5px', marginBottom: '5px' }}>{tips[activeHint].text}</div>
            <div
              style={{
                top: "60px",
                display: "flex",
                justifyContent: "space-between",
                borderTop: '1px #0B6471 solid'
              }}
            >
              <Button disabled={activeHint === 0} onClick={() => handlePrev()}>
                Cofnij
              </Button>
              <Button

                onClick={() => activeHint === tips.length - 1 ? handleClose() : handleNext()}
              >
                {activeHint === tips.length - 1 ? 'Zakończ' : 'Dalej'}
              </Button>
            </div>
          </Paper>
        </Popover>

        <Popover
          id='videoSelectPopOver'
          open={videoListOpen}
          anchorEl={document.getElementById('video')}
          onClose={handleVideoListClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
        >
          <Paper
            style={{
              padding: "10px",
              width: "450px",
              fontFamily: "roboto",
              display: "flex",
              flexDirection: "column",
              height: 'calc(100vh - 270px)'
            }}
          >

            <div
              style={{
                top: "0px",
                display: "flex",
                width: "100%",
                justifyContent: "right"
              }}
            >

              <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleVideoListClose()} />

            </div>

            <VideoList />

          </Paper>
        </Popover>

        <Dialog

          maxWidth='90vw'
          open={videoPlayDialogOpen}
          onClose={() => setVideoPlayDialogOpen(false)}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"

        >
          <DialogContent style={{ display: 'flex', flexDirection: 'column', margin: '0px', padding: '0px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 10px 3px 0px' }}>
              <button
                onClick={() => {
                  const div = document.getElementById('videoControl');
                  if (div.requestFullscreen)
                    div.requestFullscreen();
                  else if (div.webkitRequestFullscreen)
                    div.webkitRequestFullscreen();
                  else if (div.msRequestFullScreen)
                    div.msRequestFullScreen();
                }
                }
                style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
              >
                Pełen ekran
              </button>
              <button
                onClick={() => setVideoPlayDialogOpen(false)}
                style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
              >
                Zamknij
              </button>
            </div>
            <video id='videoControl' controls autoPlay style={{ width: '80vw' }}>
              <source src={selectedVideo && selectedVideo.video} type="video/mp4" />
              Video control not supported by browser
            </video>

          </DialogContent>
        </Dialog>

      </div>
      :
      <div>Wczytywanie...</div>
  );
}
